<template>
  <div>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="delinquents"
      :single-select="singleSelect"
      show-select
      class="elevation-1"
      :search="decision"
    >
      <template v-slot:top>
        <v-toolbar extended flat color="white">
          <v-col
            cols="12"
            sm="6"
          >
          <!-- <p class="mt-8 ml-6">Filter by  </p> -->
          <v-select
            :items="decisions"
            item-text="name"
            item-value="id"
            v-model="decision"
            dense
            color="grey"
            label="Decision"
            hint="Filter by Decision"
            persistent-hint
            class="mt-8 mx-4"
            v-if="delinquents.length > 0"
            solo
          />
          </v-col>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-btn rounded disabled absolute right>{{currentCenter.name}}</v-btn>
          <v-dialog v-model="dialog" max-width="1200px">
          <v-card>
            <v-card-text>
              <v-container>
                <v-row>
                        <v-col
                          cols="12"
                          sm="4"
                        >
                          <img :src="nidaPicture" width="200" class="ml-n9" height="auto"/>
                          <img :src="webcamPicture" width="300" height="auto"/>
                          </v-col>
                          <v-col
                          cols="12"
                          sm="6"
                        >
                          <v-btn rounded disabled absolute right>{{currentCenter.name}}</v-btn>
                        </v-col>
                  <v-card class="mt-2" max-width="1200" outlined raised>
                    <v-card-text>
                    <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                  >
                  <p>I. Identification</p>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      dense
                      label="First Name"
                      type="text"
                      outlined
                      v-model="editedDelinquent.firstName"
                      color="grey"
                      class="mt-n6"
                      disabled
                      filled
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      dense
                      label="Last Name"
                      type="text"
                      outlined
                      v-model="editedDelinquent.lastName"
                      color="grey"
                      class="mt-n6"
                      disabled
                      filled
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      dense
                      label="Gender"
                      outlined
                      v-model="editedDelinquent.gender"
                      class="mt-n6"
                      color="grey"
                      disabled
                      filled
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      dense
                      label="Marital Status"
                      outlined
                      v-model="editedDelinquent.maritalStatus"
                      class="mt-n6"
                      color="grey"
                      disabled
                      filled
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      dense
                      label="Father Name"
                      v-model="editedDelinquent.fatherName"
                      type="text"
                      outlined
                      name="Father Name"
                      color="grey"
                      class="mt-n6"
                      disabled
                      filled
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      dense
                      label="Mother Name"
                      type="text"
                      outlined
                      v-model="editedDelinquent.motherName"
                      name="Mother Name"
                      color="grey"
                      class="mt-n6"
                      disabled
                      filled
                    />
                  </v-col>
                  </v-row>
                  </v-card-text>
                  </v-card>
                  <v-card class="mt-2" max-width="1200" outlined raised>
                    <v-card-text>
                    <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                  >
                  <p>II. Place and Date of Birth</p>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-select
                      :items="birthProvinces"
                      item-text="name"
                      item-value="id"
                      v-model="editedDelinquent.placeOfBirth.province.id"
                      dense
                      color="grey"
                      label="Province"
                      outlined
                      class="mt-n6"
                      filled
                      disabled
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-select
                      :items="birthDistricts"
                      item-text="name"
                      item-value="id"
                      v-model="editedDelinquent.placeOfBirth.district.id"
                      dense
                      color="grey"
                      label="District"
                      outlined
                      class="mt-n6"
                      disabled
                      filled
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-select
                      :items="birthSectors"
                      item-text="name"
                      item-value="id"
                      v-model="editedDelinquent.placeOfBirth.sector.id"
                      dense
                      color="grey"
                      label="Sector"
                      outlined
                      class="mt-n6"
                      disabled
                      filled
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-select
                      :items="birthCells"
                      item-text="name"
                      item-value="id"
                      v-model="editedDelinquent.placeOfBirth.cell.id"
                      dense
                      color="grey"
                      label="Cell"
                      outlined
                      class="mt-n6"
                      disabled
                      filled
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-select
                      :items="birthVillages"
                      item-text="name"
                      item-value="id"
                      v-model="editedDelinquent.placeOfBirth.village.id"
                      dense
                      color="grey"
                      label="Village"
                      outlined
                      class="mt-n6"
                      disabled
                      filled
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      v-model="editedDelinquent.dateOfBirth"
                      label="Date of Birth"
                      outlined
                      filled
                      disabled
                      color="grey"
                      class="mt-n6"
                      dense
                    ></v-text-field>
                  </v-col>
                  </v-row>
                  </v-card-text>
                  </v-card>
                  <v-card max-width="1200" outlined raised class="mt-2">
                    <v-card-text>
                    <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                  >
                  <p>III. Place of Residence</p>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-select
                      :items="residenceProvinces"
                      item-text="name"
                      item-value="id"
                      v-model="editedDelinquent.apprehension.delinquentResidence.province.id"
                      dense
                      color="grey"
                      label="Province"
                      outlined
                      class="mt-n6"
                      filled
                      disabled
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-select
                      :items="residenceDistricts"
                      item-text="name"
                      item-value="id"
                      v-model="editedDelinquent.apprehension.delinquentResidence.district.id"
                      dense
                      color="grey"
                      label="District"
                      outlined
                      class="mt-n6"
                      filled
                      disabled
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-select
                      :items="residenceSectors"
                      item-text="name"
                      item-value="id"
                      v-model="editedDelinquent.apprehension.delinquentResidence.sector.id"
                      dense
                      color="grey"
                      label="Sector"
                      outlined
                      class="mt-n6"
                      filled
                      disabled
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-select
                      :items="residenceCells"
                      item-text="name"
                      item-value="id"
                      v-model="editedDelinquent.apprehension.delinquentResidence.cell.id"
                      dense
                      color="grey"
                      label="Cell"
                      outlined
                      class="mt-n6"
                      filled
                      disabled
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-select
                      :items="residenceVillages"
                      item-text="name"
                      item-value="id"
                      v-model="editedDelinquent.apprehension.delinquentResidence.village.id"
                      dense
                      color="grey"
                      label="Village"
                      outlined
                      class="mt-n6"
                      filled
                      disabled
                    />
                  </v-col>
                  </v-row>
                  </v-card-text>
                  </v-card>
                  <v-card max-width="1200" outlined raised class="mt-2">
                    <v-card-text>
                    <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                  >
                  <p>IV. Place of Apprehension</p>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-select
                      :items="apprehensionProvinces"
                      item-text="name"
                      item-value="id"
                      v-model="editedDelinquent.apprehension.apprehensionPlace.province.id"
                      dense
                      color="grey"
                      label="Province"
                      outlined
                      name="Apprehension Province"
                      class="mt-n6"
                      filled
                      disabled
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-select
                      :items="apprehensionDistricts"
                      item-text="name"
                      item-value="id"
                      v-model="editedDelinquent.apprehension.apprehensionPlace.district.id"
                      dense
                      color="grey"
                      label="District"
                      outlined
                      class="mt-n6"
                      filled
                      disabled
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-select
                      :items="apprehensionSectors"
                      item-text="name"
                      item-value="id"
                      v-model="editedDelinquent.apprehension.apprehensionPlace.sector.id"
                      dense
                      color="grey"
                      label="Sector"
                      outlined
                      class="mt-n6"
                      filled
                      disabled
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-select
                      :items="apprehensionCells"
                      item-text="name"
                      item-value="id"
                      v-model="editedDelinquent.apprehension.apprehensionPlace.cell.id"
                      dense
                      color="grey"
                      label="Cell"
                      outlined
                      class="mt-n6"
                      filled
                      disabled
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-select
                      :items="apprehensionVillages"
                      item-text="name"
                      item-value="id"
                      v-model="editedDelinquent.apprehension.apprehensionPlace.village.id"
                      dense
                      color="grey"
                      label="Village"
                      outlined
                      class="mt-n6"
                      filled
                      disabled
                    />
                  </v-col>
                  </v-row>
                  </v-card-text>
                  </v-card>
                  <v-card width="1200" outlined raised class="mt-2">
                    <v-card-text>
                    <v-row>
                      <v-col
                    cols="12"
                    sm="12"
                  >
                  <p>V. How he/she was apprehended and act he/she was doing</p>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                  >
                    <v-text-field
                      dense
                      label="How he/she was apprehended  and act he/she was doing"
                      type="text"
                      outlined
                      v-model="editedDelinquent.apprehension.apprehensionWay"
                      color="grey"
                      class="mt-n6"
                      filled
                      disabled
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      dense
                      label="Deviant Acts"
                      type="text"
                      outlined
                      v-model="editedDelinquent.apprehension.deviantActs"
                      name="Last Name"
                      color="grey"
                      class="mt-n6"
                      disabled
                      filled
                    />
                  </v-col>
                  </v-row>
                  </v-card-text>
                  </v-card>
                  <v-card max-width="1200" outlined raised class="mt-2">
                    <v-card-text>
                    <v-row>
                      <v-col
                    cols="12"
                    sm="12"
                  >
                  <p>Additional Details</p>
                   </v-col>
                      <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      dense
                      label="Specific Disease"
                      type="text"
                      outlined
                      v-model="editedDelinquent.specificDisease"
                      color="grey"
                      class="mt-n6"
                      filled
                      disabled
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      dense
                      label="Next Of Kin Phone"
                      type="text"
                      outlined
                      v-model="editedDelinquent.guardianPhone"
                      color="grey"
                      class="mt-n6"
                      filled
                      disabled
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      dense
                      label="Homeless Status"
                      v-model="editedDelinquent.homeless"
                      color="grey"
                      class="mt-n6"
                      filled
                      disabled
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      dense
                      label="Father Vital Status"
                      outlined
                      v-model="editedDelinquent.fatherVitalStatus"
                      color="grey"
                      filled
                      class="mt-n6"
                      disabled
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      dense
                      label="Mother Vital Status"
                      outlined
                      v-model="editedDelinquent.motherVitalStatus"
                      color="grey"
                      filled
                      class="mt-n6"
                      disabled
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      dense
                      label="Education"
                      outlined
                      v-model="editedDelinquent.educationLevel"
                      color="grey"
                      filled
                      class="mt-n6"
                      disabled
                    />
                  </v-col>
                    </v-row>
                  </v-card-text>
                  </v-card>
                  <v-card width="1200" outlined raised class="mt-2">
                    <v-card-text>
                    <v-row>
                      <v-col
                    cols="12"
                    sm="12"
                  >
                  <p>VI. Decision Taken</p>
                   </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                      <v-text-field
                        v-model="editedDelinquent.latestScreening.meetingDate"
                        label="Meeting Date"
                        outlined
                        filled
                        color="grey"
                        class="mt-n6"
                        dense
                        disabled
                      ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      dense
                      label="Decision taken"
                      outlined
                      v-model="editedDelinquent.latestScreening.decision"
                      color="grey"
                      filled
                      class="mt-n6"
                      disabled
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      dense
                      label="Select"
                      outlined
                      v-model="editedDelinquent.latestScreening.transferCenter"
                      color="grey"
                      filled
                      class="mt-n6"
                      v-if="editedDelinquent.latestScreening.transferCenter !== null"
                      disabled
                    />
                  </v-col>
                    </v-row>
                  </v-card-text>
                  </v-card>
                  <v-card width="1200" outlined raised class="mt-2">
                    <v-card-text>
                    <v-row>
                    <v-col
                    cols="12"
                    sm="12"
                  >
                  <p>VII. Reason for decision taken</p>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                  >
                    <v-text-field
                      dense
                      label="Reason for decision taken"
                      type="text"
                      outlined
                      v-model="editedDelinquent.latestScreening.decisionReason"
                      color="grey"
                      class="mt-n6"
                      filled
                      disabled
                    />
                  </v-col>
                  </v-row>
                  </v-card-text>
                  </v-card>
                  <v-card width="1200" outlined raised class="mt-2">
                    <v-card-text>
                    <v-row>
                    <v-col
                    cols="12"
                    sm="12"
                  >
                  <p>VIII. Opinion of the person received in transit center, his/her parent or next of kin</p>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                  >
                    <v-text-field
                      dense
                      label="Apprehendee opinion"
                      type="text"
                      outlined
                      v-model="editedDelinquent.latestScreening.delinquentPointOfView"
                      color="grey"
                      class="mt-n6"
                      filled
                      disabled
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                  >
                    <v-text-field
                      dense
                      label="Parent/Next Of Kin opinion"
                      type="text"
                      outlined
                      v-model="editedDelinquent.latestScreening.guardianPointOfView"
                      color="grey"
                      class="mt-n6"
                      filled
                      disabled
                    />
                  </v-col>
                  </v-row>
                  </v-card-text>
                  </v-card>
                   <v-card width="1200" outlined raised class="mt-2">
                    <v-card-text>
                    <v-row>
                    <v-col
                    cols="12"
                    sm="12"
                  >
                  <p>IX. MedicalTests</p>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <p class="ml-6">{{medicalTest1}} :</p>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                  <v-text-field
                      dense
                      label="Test Result"
                      outlined
                      v-model="medicalTest1Result"
                      color="grey"
                      filled
                      disabled
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    class="mt-n8"
                  >
                    <p class="ml-6">{{medicalTest2}} :</p>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    class="mt-n8"
                  >
                  <v-text-field
                      dense
                      label="Test Result"
                      outlined
                      v-model="medicalTest2Result"
                      color="grey"
                      filled
                      disabled
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    class="mt-n8"
                  >
                  <p class="ml-6">{{medicalTest3}} :</p>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    class="mt-n8"
                  >
                  <v-text-field
                      dense
                      label="Mental Health Status"
                      type="text"
                      outlined
                      v-model="medicalTest3Result"
                      color="grey"
                      filled
                      disabled
                    />
                  </v-col>
                  </v-row>
                  </v-card-text>
                  </v-card>
                  <v-card width="1200" outlined raised class="mt-2">
                    <v-card-text>
                    <v-row>
                    <v-col
                    cols="12"
                    sm="12"
                  >
                  <p>X. Screening Resolutions</p>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <p class="ml-6">Decision :</p>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                  <v-text-field
                      dense
                      label="Test Result"
                      outlined
                      v-model="editedDelinquent.latestTransfer.eligibilityState"
                      color="grey"
                      filled
                      disabled
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    class="mt-n8"
                  >
                  <p class="ml-6">Comment :</p>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    class="mt-n8"
                  >
                  <v-text-field
                      dense
                      label="Comment"
                      type="text"
                      outlined
                      v-model="editedDelinquent.latestTransfer.eligibilityComment"
                      color="grey"
                      filled
                      disabled
                    />
                  </v-col>
                  </v-row>
                  </v-card-text>
                  </v-card>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="viewDelinquent(item)"
        >
          mdi-eye
        </v-icon>
      </template>
    </v-data-table>
    <v-snackbar
    v-model="snackbar"
    top
    :color="color"
    :timeout="timeout"
  >
    {{ text }}
    <template v-slot:action="{ attrs }">
      <v-btn
        color="blue"
        icon
        v-bind="attrs"
        @click="setSnackbar(false)"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
    <v-dialog v-model="approveDialog" hide-overlay max-width="500px">
    <v-card tile outlined>
        <v-card-text>
          <v-textarea
          filled
          outlined
          auto-grow
          v-model="approvalComment"
          label="Approval Comment"
          rows="3"
          class="mt-4"
          row-height="20"
        ></v-textarea>
          <v-btn link small color="blue" @click="approve" :disabled="disableRequest">Yes</v-btn>
          <v-btn link small color="blue" @click="approveDialog = false" :disabled="disableRequest">No</v-btn>
        </v-card-text>
      </v-card>
  </v-dialog>
  <v-dialog v-model="rejectDialog" hide-overlay max-width="500px" persistent>
    <v-card tile outlined>
        <v-card-text>
          <v-textarea
          filled
          outlined
          auto-grow
          v-model="rejectComment"
          label="Reject Comment"
          rows="3"
          class="mt-4"
          row-height="20"
        ></v-textarea>
          <v-btn link small color="blue" @click="reject" :disabled="disableRequest">Yes</v-btn>
          <v-btn link small color="blue" @click="rejectDialog = false" :disabled="disableRequest">No</v-btn>
        </v-card-text>
      </v-card>
  </v-dialog>
    <v-btn color="green" dark class="ml-4 mt-4" @click="approvingDialog()" v-if="showApprove && delinquents.length > 0">Approve</v-btn>
    <v-btn color="red" dark class="ml-4 mt-4" @click="rejectingDialog()" v-if="showApprove && delinquents.length > 0">Reject</v-btn>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import Delinquent from '@/models/Delinquent'
export default {
  data: () => ({
    privileges: null,
    dialog: false,
    approveDialog: false,
    rejectDialog: false,
    showApprove: false,
    disableRequest: false,
    delinquents: [],
    decision: '',
    approvalComment: null,
    rejectComment: null,
    singleSelect: false,
    selected: [],
    headers: [
      {
        text: 'Names',
        align: 'start',
        sortable: false,
        value: 'names'
      },
      { text: 'Apprehension District', value: 'apprehension.apprehensionPlace.district.name' },
      { text: 'Apprehension Sector', value: 'apprehension.apprehensionPlace.sector.name' },
      { text: 'Date of Apprehension', value: 'apprehension.apprehensionDate' },
      { text: 'General Decision', value: 'latestScreening.decision' },
      { text: 'Medical Decision', value: 'eligibilityState' },
      { text: 'Approval Status', value: 'latestScreening.approvalStatus' },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    medicalTest1: 'Medical Test 1 (HIV/SIDA)',
    medicalTest2: 'Medical Test 2 (TB)',
    medicalTest3: 'Mental Health Status',
    medicalTest1Result: null,
    medicalTest2Result: null,
    medicalTest3Result: null,
    birthProvinces: [],
    apprehensionProvinces: [],
    residenceProvinces: [],
    birthDistricts: [],
    apprehensionDistricts: [],
    residenceDistricts: [],
    birthSectors: [],
    apprehensionSectors: [],
    residenceSectors: [],
    birthCells: [],
    apprehensionCells: [],
    residenceCells: [],
    birthVillages: [],
    apprehensionVillages: [],
    residenceVillages: [],
    editedDelinquent: new Delinquent(),
    webcamPicture: null,
    nidaPicture: null
  }),

  computed: {
    ...mapState('store', ['snackbar', 'color', 'text', 'timeout', 'decisions', 'currentUser', 'search', 'currentCenter', 'storeProvinces', 'storeProvincesError'])
  },
  mounted () {
    if (this.decisions.length === 0) this.loadDecisions()
  },

  created () {
    this.loadDelinquents()
    this.setDecisionUnshift()
    this.loadBirthProvinces()
    this.privileges = this.currentUser.privileges
    this.checkPrivileges()
  },
  destroyed () {
    this.setSnackbar(false)
  },

  methods: {
    ...mapActions('store', ['loadStoreProvinces', 'loadDecisions', 'loadStoreProvinces']),
    ...mapMutations('store', {
      setSnackbar: 'SET_SNACKBAR',
      setColor: 'SET_COLOR',
      setText: 'SET_TEXT',
      setDecisionUnshift: 'SET_DECISIONS_UNSHIFT'
    }),
    loadDelinquents () {
      this.$delinquentService.fetchAllTransfers('screening_decision', true, 'REQUESTED')
        .then(response => {
          if (response.content) {
            this.delinquents = response.content
            this.delinquents.forEach(element => {
              element.names = element.delinquent.firstName + ' ' + element.delinquent.lastName
            })
          } else {
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Read Delinquents: ' + response.message)
          }
        })
    },
    updateApprovalStatus (response) {
      response.forEach(requestForApprovalresponse => {
        const delinquent = this.delinquents.find(del => requestForApprovalresponse.id === del.id)
        delinquent.latestScreening.approvalStatus = requestForApprovalresponse.latestScreening.approvalStatus
      })
    },
    approve () {
      this.disableRequest = true
      this.selected.forEach(element => {
        element.latestScreening.approvalStatus = 'APPROVED'
        element.latestScreening.approvalComment = this.approvalComment
      })
      this.$delinquentService.approval(this.selected)
        .then(response => {
          if (response[0].id) {
            this.disableRequest = false
            this.loadDelinquents()
            // this.updateApprovalStatus(response)
            this.approveDialog = false
            this.selected = []
            this.setSnackbar(true)
            this.setColor('green')
            this.setText('The request is approved successfully')
          } else {
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
            this.disableRequest = false
          }
        })
    },
    reject () {
      if (this.rejectComment) {
        this.disableRequest = true
        this.selected.forEach(element => {
          element.latestScreening.approvalStatus = 'REJECTED'
          element.latestScreening.approvalComment = this.rejectComment
        })
        this.$delinquentService.approval(this.selected)
          .then(response => {
            if (response[0].id) {
              this.disableRequest = false
              this.loadDelinquents()
              // this.updateApprovalStatus(response)
              this.rejectDialog = false
              this.selected = []
              this.setSnackbar(true)
              this.setColor('green')
              this.setText('The request is rejected successfully')
            } else {
              // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
              this.setSnackbar(true)
              this.setColor('red')
              this.setText(response.message)
              this.disableRequest = false
            }
          })
      } else {
        this.setSnackbar(true)
        this.setColor('red')
        this.setText('Enter reject comment')
      }
    },
    viewDelinquent (item) {
      this.$delinquentService.loadDelinquentIdentification(item.delinquent.id, 'latest_apprehension,general_screening,medical_screening')
        .then(response => {
          if (response.id) {
            this.editedDelinquent = response
            const [medicalTest1Result, medicalTest2Result, medicalTest3Result] = Object.values(this.editedDelinquent.latestMedicalTestResults)
            this.medicalTest1Result = medicalTest1Result
            this.medicalTest2Result = medicalTest2Result
            this.medicalTest3Result = medicalTest3Result
            this.retrievedDateOfBirth = response.dateOfBirth
            this.retrievedMeetingDate = response.latestScreening.meetingDate
            this.fetchBirthSiblings()
            this.fetchApprehensionSiblings()
            this.fetchResidenceSiblings()
            this.webcamPicture = response.picture
          } else {
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
      this.dialog = true
    },
    approvingDialog () {
      if (this.selected.length > 0) {
        this.approveDialog = true
      } else {
        this.setSnackbar(true)
        this.setColor('orange')
        this.setText('No delinquents selected')
      }
    },
    rejectingDialog () {
      if (this.selected.length > 0) {
        this.rejectDialog = true
      } else {
        this.setSnackbar(true)
        this.setColor('orange')
        this.setText('No delinquents selected')
      }
    },
    loadBirthProvinces () {
      if (this.storeProvinces.length > 0) {
        this.birthProvinces = this.storeProvinces
        this.apprehensionProvinces = this.storeProvinces
        this.residenceProvinces = this.storeProvinces
      } else this.reloadStoreProvinces()
    },
    async reloadStoreProvinces () {
      await this.loadStoreProvinces
      if (this.storeProvinces.length > 0) {
        this.birthProvinces = this.storeProvinces
        this.apprehensionProvinces = this.storeProvinces
        this.residenceProvinces = this.storeProvinces
      } else {
        this.setSnackbar(true)
        this.setColor('red')
        this.setText('Provinces: ' + this.storeProvincesError)
      }
    },
    fetchBirthSiblings () {
      this.$locationService.fetchAllSiblings(this.editedDelinquent.placeOfBirth).then(response => {
        if (response.status) {
          this.setSnackbar(true)
          this.setColor('red')
          this.setText('Location: ' + response.message)
        } else [this.birthDistricts, this.birthSectors, this.birthCells, this.birthVillages] = response
      })
    },
    fetchApprehensionSiblings () {
      this.$locationService.fetchAllSiblings(this.editedDelinquent.apprehension.apprehensionPlace).then(response => {
        if (response.status) {
          this.setSnackbar(true)
          this.setColor('red')
          this.setText('Location: ' + response.message)
        } else [this.apprehensionDistricts, this.apprehensionSectors, this.apprehensionCells, this.apprehensionVillages] = response
      })
    },
    fetchResidenceSiblings () {
      this.$locationService.fetchAllSiblings(this.editedDelinquent.apprehension.delinquentResidence).then(response => {
        if (response.status) {
          this.setSnackbar(true)
          this.setColor('red')
          this.setText('Location: ' + response.message)
        } else [this.residenceDistricts, this.residenceSectors, this.residenceCells, this.residenceVillages] = response
      })
    },
    checkPrivileges () {
      this.privileges.includes('APPROVE_SCREENINGS') || this.privileges.includes('ALL_AUTHORITIES') ? this.showApprove = true : this.showApprove = false
    }
  }
}
</script>
